import { Flex, Space, Switch, Table, TableColumnsType, Tag, Typography, message, notification } from "antd";
import { useEffect, useRef, useState } from "react";
import { SearchStoreMasterParams, StoreMasterParams, StoreMasterType, StoreType } from "../models/store";
import { TableRowSelection } from "antd/es/table/interface";
import { CheckOutlined, CopyOutlined, PlusOutlined, StopOutlined } from "@ant-design/icons";
import StoreMasterService from "../services/store-master-service";
import { ButtonDs } from "design-system";
import TooltipComponent from "../../../shared/component/TooltipComponent";
import { NotificationComponent } from "../../../shared/component/NotificationComponent";
import { GetDateTime } from "../../../utils/GetDateNow";
import LocalstorageService from "../../../shared/service/Localstorage.service";
import CreateStoreModal from "./CreateStoreModal";
import { useNavigate } from "react-router-dom";
import { ColumnGroupType } from "antd/lib/table";
interface Props {
  dataSource: StoreMasterType | undefined;
  searchParam: SearchStoreMasterParams;
  onUpdateTable: () => void;
}

enum TableKeySelectionType {
  ALL = "all",
  THIS_PAGE = "this_page",
  NONE = "none",
}

const DataTable = ({ dataSource, searchParam, onUpdateTable }: Props) => {
  const { Text } = Typography;
  const userRole = LocalstorageService.getCurrentRole();
  const [messageApi, contextHolderMessage] = message.useMessage();

  const [dataDisplay, setDataDisplay] = useState<StoreType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = useRef(15);
  const [paginationTotal, setPaginationTotal] = useState(0);
  const [rowKeysSelected, setRowKeysSelected] = useState<string[]>([]);

  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isEnableNotiLoading, setIsEnableNotiLoading] = useState(false);
  const [isDisableNotiLoading, setIsDisableNotiLoading] = useState(false);
  const [tableKeySelectedType, setTableKeySelectedType] = useState<TableKeySelectionType>(
    TableKeySelectionType.NONE,
  );

  const [notificationComponent, contextHolderNoti] = notification.useNotification();
  const [isPatchingEnableGeofence, setIsPatchingEnableGeofence] = useState(false);
  const [openCreateStoreModal, setOpenCreateStoreModal] = useState(false);
  const newStoreRef = useRef<StoreType | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    StoreMasterService.isloadingTable.subscribe((loading) => {
      setIsLoadingTable(loading);
    });
  }, []);

  useEffect(() => {
    setTableDataDisplay();
    setCurrentPage(1);
    setRowKeysSelected([]);
    setTableKeySelectedType(TableKeySelectionType.NONE);
    setIsLoadingTable(false);
  }, [dataSource]);

  const onPageChange = async ({ current, pageSize }: any) => {
    const nextPageTotal = 3 * pageSize;
    const dataDisplayLength = dataDisplay.length;

    if (dataDisplay && dataDisplayLength - current * pageSize <= nextPageTotal) {
      setIsLoadingTable(true);
      const start_after_store_number = dataDisplay[dataDisplayLength - 1].store_number;
      const searchStoreMasterParam: SearchStoreMasterParams = {
        cursor: {
          start_after_store_number: start_after_store_number,
        },
        limit: nextPageTotal,
        province: searchParam.province,
        store_name: searchParam.store_name,
        store_number: searchParam.store_number,
        enabled_notification: searchParam.enabled_notification,
        enabled_checking_geofence: searchParam.enabled_checking_geofence,
        is_registered: searchParam.is_registered,
      };
      StoreMasterService.searchStoreMaster(searchStoreMasterParam)
        .then((res: StoreMasterType) => {
          const data = res.data;
          if (res) {
            setPaginationTotal(paginationTotal + data.length);
            const newData = dataDisplay ? dataDisplay.concat(data || []) : data;
            setDataDisplay(newData);
            checkKeysSelection(newData);
          }
        })
        .finally(() => {
          setIsLoadingTable(false);
        });
    }
  };

  const setTableDataDisplay = () => {
    const newStore = newStoreRef.current;
    if (dataSource !== undefined) {
      if (newStore) {
        const newStoreIndex = dataSource.data.findIndex(
          (store) => store.store_number === newStore.store_number,
        );
        if (newStoreIndex !== -1) {
          dataSource.data.splice(newStoreIndex, 1);
        }
        dataSource.data = [newStore, ...dataSource.data];
        newStoreRef.current = null;
      }
      setDataDisplay(dataSource.data);
      setPaginationTotal(dataSource.data.length);
    } else {
      setDataDisplay([]);
      setPaginationTotal(0);
    }
  };
  const onAddNewStore = (newStore: StoreType) => {
    updateTable();
    newStoreRef.current = newStore;
  };

  const copyToClipBoard = async (responseText: string) => {
    await navigator.clipboard
      .writeText(responseText)
      .then(() => {
        diaplayNotification(`คัดลอก : ${responseText}`);
      })
      .catch(() => {
        messageApi.error({
          className: "store-master-message",
          content: "คัดลอกไม่สำเร็จ",
        });
      });
  };

  const renderTag = (color: string, textColor: string, text: string) => {
    return (
      <Tag bordered={false} color={color} style={{ color: textColor, fontSize: "12px" }}>
        {text}
      </Tag>
    );
  };

  const toggleCheckingGeofence = async (idx: number, value: boolean) => {
    const selectDataIdx = currentPage * pageSize.current - pageSize.current + idx;
    const storeData = dataDisplay[selectDataIdx];
    try {
      setIsPatchingEnableGeofence(true);
      await StoreMasterService.toggleCheckingGeofence(storeData.store_number, value);
      if (value) {
        diaplayNotification(`เปิดการตรวจสอบตำแหน่ง ${storeData.store_name} สำเร็จ`, "", "success");
      } else {
        diaplayNotification(`ปิดการตรวจสอบตำแหน่ง ${storeData.store_name} สำเร็จ`, "", "success");
      }
      setDataDisplay(
        dataDisplay.map((data, index) => {
          if (index === selectDataIdx) {
            return { ...data, enabled_checking_geofence: value };
          }
          return data;
        }),
      );
    } catch (e) {
      if (value) {
        diaplayNotification(`เปิดการตรวจสอบตำแหน่ง ${storeData.store_name} ไม่สำเร็จ`, "", "error");
      } else {
        diaplayNotification(`ปิดการตรวจสอบตำแหน่ง ${storeData.store_name} ไม่สำเร็จ`, "", "error");
      }
    } finally {
      setIsPatchingEnableGeofence(false);
    }
  };

  const renderColumn = (): TableColumnsType<StoreType> => {
    const standardColumns: TableColumnsType<StoreType> = [
      {
        title: "รหัสร้านค้า",
        dataIndex: "store_number",
        key: "store_number",
        fixed: "left",
      },
      {
        title: "ชื่อร้านค้า",
        dataIndex: "store_name",
        key: "store_name",
        fixed: "left",
        render: (value, record) => {
          return (
            <Space>
              <div>{`${record.store_name} `}</div>
              {record.is_new_store && (
                <Tag color="blue" style={{ fontSize: 12, height: 22 }}>
                  New
                </Tag>
              )}
              {record.is_updated_store && (
                <Tag color="default" style={{ fontSize: 12, height: 22, color: "blue" }}>
                  Update
                </Tag>
              )}
            </Space>
          );
        },
      },
      {
        title: "จังหวัด",
        dataIndex: "province",
        key: "province",
        width: 120,
      },
      {
        title: "Lat, Long",
        key: "latLong",
        align: "center",
        width: 100,
        render: (_, record: StoreType) => {
          const location = `${record.lat}, ${record.long}`;
          return (
            <TooltipComponent
              title={
                <a
                  style={{ textDecoration: "underline" }}
                  href={`http://maps.google.com/?q=${location}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {location}
                </a>
              }
              placement="top"
              children={
                <a
                  style={{ color: "black" }}
                  data-testid="copy-to-clipboard-buton"
                  onClick={() => copyToClipBoard(`${record.lat}, ${record.long}`)}
                >
                  <CopyOutlined rev={""} />
                </a>
              }
            />
          );
        },
      },
      {
        title: "เบอร์โทร",
        dataIndex: "mobile_phone",
        key: "mobile_phone",
      },
      {
        title: "หมายเหตุ",
        dataIndex: "remark",
        key: "remark",
        render: (text: string, record: StoreType) => (
          <Text
            ellipsis={{
              tooltip: {
                title: `${record.remark}`,
                color: "white",
                overlayInnerStyle: { color: "black", backgroundColor: "white" },
              },
            }}
          >
            {record.remark !== "" ? record.remark : "-"}
          </Text>
        ),
      },
      {
        title: "สถานะการลงทะเบียน",
        key: "registered_status",
        align: "center",
        render: (_, record: StoreType) =>
          record.is_registered
            ? renderTag("#EDF0FE", "#4765F2", "ลงทะเบียน")
            : renderTag("#F6F7FB", "#A8B0C2", "ยังไม่ลงทะเบียน"),
      },
      {
        title: "ประทับเวลาลงทะเบียน",
        dataIndex: "registered_at",
        key: "registered_at",
        render: (_, record: StoreType) => (
          <p>{record.registered_at ? GetDateTime(record.registered_at) : "-"}</p>
        ),
      },
      {
        title: "การอนุญาตส่งข้อความ",
        key: "allow_send_message",
        align: "center",
        render: (_, record: StoreType) =>
          record.enabled_notification && record.enabled_notification
            ? renderTag("#F5FFF7", "#006D2E", "อนุญาต")
            : renderTag("#FFF2F2", "#E11919", "ไม่อนุญาต"),
      },
      {
        title: "การตรวจสอบตำแหน่ง",
        key: "allow_check_geofence",
        align: "center",
        render: (_, record: StoreType, idx: number) => (
          <Switch
            value={record.enabled_checking_geofence}
            loading={isPatchingEnableGeofence}
            disabled={!userRole?.includes("admin")}
            onChange={(value) => toggleCheckingGeofence(idx, value)}
          />
        ),
      },
    ];

    const actionColumn: Partial<ColumnGroupType<StoreType>> = {
      title: "Action",
      key: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <ButtonDs
          size="middle"
          type="default"
          onClick={() => {
            navigate(`/store-master/${record.store_number}`);
          }}
        >
          รายละเอียด
        </ButtonDs>
      ),
      width: 150,
    };
    return userRole?.includes("admin") || userRole?.includes("manager")
      ? [...standardColumns, actionColumn]
      : standardColumns;
  };

  const columns: TableColumnsType<StoreType> = renderColumn();

  const checkKeysSelection = (dataList: StoreType[]) => {
    if (tableKeySelectedType === TableKeySelectionType.ALL) {
      const data = dataList.map((item) => item.store_number);
      setRowKeysSelected(data);
    }
  };

  const onSelectChange = (record: any, selected: boolean, selectedRows: any) => {
    if (tableKeySelectedType !== TableKeySelectionType.ALL) {
      const newSelectedList = selectedRows.map((item: any) => item.store_number);
      setRowKeysSelected(newSelectedList);
    }
  };

  const onChange = (_: any, selectedRows: any, { type }: any) => {
    if (type === "all") {
      setTableKeySelectedType(TableKeySelectionType.THIS_PAGE);

      if (selectedRows.length === 0) {
        setTableKeySelectedType(TableKeySelectionType.NONE);
      }
    }
    setRowKeysSelected(selectedRows.map((item: any) => item.store_number));
  };

  const toggleSelectAll = () => {
    setRowKeysSelected((keys) =>
      keys.length === dataDisplay.length ? [] : dataDisplay.map((r) => r.store_number),
    );
  };

  const toggleSelectThisPage = () => {
    const startPageIndex = (currentPage - 1) * pageSize.current;
    const endPageIndex = startPageIndex + pageSize.current;
    const newList = dataDisplay.slice(startPageIndex, endPageIndex).map((r) => r.store_number);
    setRowKeysSelected(newList);
  };

  const rowSelection: TableRowSelection<StoreType> = {
    selectedRowKeys: rowKeysSelected,
    onChange: onChange,
    onSelect: onSelectChange,
    getCheckboxProps: () => ({
      disabled: tableKeySelectedType === TableKeySelectionType.ALL,
    }),
    selections: [
      {
        key: "all",
        text: "All",
        onSelect: () => {
          setTableKeySelectedType(TableKeySelectionType.ALL);
          toggleSelectAll();
        },
      },
      {
        key: "thisPage",
        text: "This Page",
        onSelect: () => {
          setTableKeySelectedType(TableKeySelectionType.THIS_PAGE);
          toggleSelectThisPage();
        },
      },
      {
        key: "none",
        text: "None",
        onSelect: () => {
          setTableKeySelectedType(TableKeySelectionType.NONE);
          setRowKeysSelected([]);
        },
      },
    ],
  };

  const customLocale = {
    selectInvert: "This Page",
    selectNone: "None",
  };

  const diaplayNotification = (message: string, topic?: string, type: "success" | "error" = "success") => {
    return NotificationComponent({
      notification: notificationComponent,
      type,
      topic: topic,
      message: message,
    });
  };

  const updateTable = () => {
    setRowKeysSelected([]);
    setTableKeySelectedType(TableKeySelectionType.NONE);
    onUpdateTable();
  };

  const getEnableNotiParam = () => {
    const params: StoreMasterParams = {
      province: searchParam.province,
      store_name: searchParam.store_name,
      store_number: searchParam.store_number,
      enabled_notification: searchParam.enabled_notification,
      is_registered: searchParam.is_registered,
      enabled_checking_geofence: searchParam.enabled_checking_geofence,
    };

    return params;
  };

  const onClickEnableNoti = async () => {
    setIsLoadingTable(true);
    setIsEnableNotiLoading(true);
    StoreMasterService.signalResetForm.next(false);
    if (tableKeySelectedType === TableKeySelectionType.ALL) {
      // Enabled send all notification
      await StoreMasterService.enableSendNotificationAll(getEnableNotiParam())
        .then(() => {
          StoreMasterService.signalNotiFail.next(false);
          diaplayNotification("อนุญาตให้ส่งข้อความทั้งหมดสำเร็จ");
          updateTable();
        })
        .catch(() => {
          StoreMasterService.signalNotiFail.next(true);
        })
        .finally(() => {
          StoreMasterService.signalResetForm.next(true);
        });
    } else {
      await StoreMasterService.enableSendNotification(rowKeysSelected)
        .then(() => {
          StoreMasterService.signalNotiFail.next(false);
          diaplayNotification("อนุญาตให้ส่งข้อความสำเร็จ");
          updateTable();
        })
        .catch(() => {
          StoreMasterService.signalNotiFail.next(true);
        })
        .finally(() => {
          StoreMasterService.signalResetForm.next(true);
        });
    }
    setIsLoadingTable(false);
    setIsEnableNotiLoading(false);
  };

  const onClickDisableNoti = async () => {
    setIsLoadingTable(true);
    setIsDisableNotiLoading(true);
    StoreMasterService.signalResetForm.next(false);
    if (tableKeySelectedType === TableKeySelectionType.ALL) {
      // Disabled send all notification
      await StoreMasterService.disableSendNotificationAll(getEnableNotiParam())
        .then(() => {
          StoreMasterService.signalNotiFail.next(false);
          diaplayNotification("ไม่อนุญาตให้ส่งข้อความทั้งหมดสำเร็จ");
          updateTable();
        })
        .catch(() => {
          StoreMasterService.signalNotiFail.next(true);
        })
        .finally(() => {
          StoreMasterService.signalResetForm.next(true);
        });
    } else {
      await StoreMasterService.disableSendNotification(rowKeysSelected)
        .then(() => {
          StoreMasterService.signalNotiFail.next(false);
          diaplayNotification("ไม่อนุญาตให้ส่งข้อความสำเร็จ");
          updateTable();
        })
        .catch(() => {
          StoreMasterService.signalNotiFail.next(true);
        })
        .finally(() => {
          StoreMasterService.signalResetForm.next(true);
        });
    }
    setIsLoadingTable(false);
    setIsDisableNotiLoading(false);
  };

  return (
    <>
      {userRole?.includes("admin") && (
        <div className="group-action-button">
          <div className="send-message-button">
            <ButtonDs
              size="middle"
              type="primary"
              icon={<CheckOutlined />}
              loading={isEnableNotiLoading}
              disabled={rowKeysSelected.length < 1 || isDisableNotiLoading || !userRole?.includes("admin")}
              onClick={onClickEnableNoti}
            >
              อนุญาตให้ส่งข้อความ
            </ButtonDs>
            <ButtonDs
              size="middle"
              type="primary"
              icon={<StopOutlined />}
              loading={isDisableNotiLoading}
              disabled={rowKeysSelected.length < 1 || isEnableNotiLoading || !userRole?.includes("admin")}
              onClick={onClickDisableNoti}
            >
              ไม่อนุญาตให้ส่งข้อความ
            </ButtonDs>
          </div>
        </div>
      )}
      <Flex style={{ marginBottom: "16px" }} justify="space-between" align="center">
        <p className="show-seleted-and-total">
          เลือกทั้งหมด{" "}
          {tableKeySelectedType === TableKeySelectionType.ALL ? dataSource?.total : rowKeysSelected.length}{" "}
          จาก {dataSource?.total} รายการ
        </p>
        {userRole?.includes("admin") || userRole?.includes("manager") ? (
          <ButtonDs
            type="primary"
            onClick={() => setOpenCreateStoreModal(true)}
            data-testid="add-store-button"
          >
            <PlusOutlined /> เพิ่มร้านค้า
          </ButtonDs>
        ) : null}
      </Flex>
      <Table
        rowSelection={
          userRole?.includes("admin")
            ? {
                type: "checkbox",
                ...rowSelection,
              }
            : undefined
        }
        scroll={{ x: 1200 }}
        sticky={{ offsetHeader: 0 }}
        loading={isLoadingTable}
        rowKey={(row) => row.store_number}
        columns={columns}
        dataSource={dataDisplay}
        onChange={onPageChange}
        pagination={{
          pageSize: pageSize.current,
          showSizeChanger: false,
          total: paginationTotal,
          current: currentPage,
          onChange(page) {
            setCurrentPage(page);
          },
        }}
        locale={customLocale}
      />
      <CreateStoreModal
        open={openCreateStoreModal}
        onClose={() => setOpenCreateStoreModal(false)}
        onOk={onAddNewStore}
      />
      {contextHolderMessage}
      {contextHolderNoti}
    </>
  );
};

export default DataTable;
