export const phoneNumberValidator = (_: any, value: string) => {
  if (value) {
    if (value.length === 10) {
      return Promise.resolve();
    } else if (value.length !== 10) {
      return Promise.reject("กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง (หมายเลข 10 หลัก)");
    }
  } else {
    return Promise.reject("กรุณากรอกเบอร์โทรศัพท์");
  }
};
