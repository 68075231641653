import { Col, Modal, Radio, Row } from "antd";
import { ButtonDs, Form, FormDs, InputDs, SelectDs } from "design-system";
import { useEffect, useState } from "react";
import CardBox from "../../../shared/component/CardBox";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { AssignTruckRequest, BookingInfo, TruckInfo } from "../models/booking";
import BookingRequestService from "../services/booking-request-service";
import React from "react";
import AlertMessage from "../../../shared/component/AlertMessage";
import { useNavigate } from "react-router-dom";
import StateService from "../../../shared/service/State.service";
import { phoneNumberValidator } from "../../../utils/validators";
import { formatPhoneNumber } from "../../../utils/normalizers";

interface Props {
  bookingId: string;
  bookingInfo: BookingInfo | undefined;
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}
const ModalAssignTruck = ({ open, onCancel, bookingInfo, bookingId }: Props) => {
  const [form] = Form.useForm();
  const [formUpdated, setFormUpdated] = useState<boolean>(true);
  const [assignTruckRequest, setAssignTruckRequest] = useState<AssignTruckRequest>();
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);
  const navigate = useNavigate();
  const [subbyList, setSubbyList] = useState<{ value: string; label: string }[]>();

  const truckTypes = [
    "4W",
    "4WJB",
    "4WCR",
    "4WJCR",
    "6W",
    "6W 5.5",
    "6W 7.2",
    "6WT",
    "6WCR",
    "10W",
    "10WT",
    "10WCR",
    "12W",
    "TR",
  ];

  useEffect(() => {
    if (open) {
      form.resetFields();
      setIsModalConfirmOpen(false);
      setIsError(false);
    }

    if (bookingInfo?.status === "confirmed") {
      form.setFieldsValue({
        trucks: bookingInfo?.truck_list.map((x) => ({ ...x, subby: x.subby_id })) || [],
        remark_to_customer: bookingInfo?.remark || "",
      });
    }
  }, [open]);

  useEffect(() => {
    StateService.subbyListSubject.subscribe((res) => {
      if (res) {
        setSubbyList(res);
      }
    });
  }, []);

  const onClickCancle = () => {
    onCancel();
  };

  const onClickSubmit = (value: any) => {
    form
      .validateFields()
      .then(() => {
        const trucksValue: any[] = value.trucks;

        const trucksList: TruckInfo[] = trucksValue.map((x) => {
          return {
            driver_name: x.driver_name,
            driver_mobile: x.driver_mobile,
            truck_type: x.truck_type,
            truck_owner: x.truck_owner,
            truck_plate: x.truck_plate,
            trailer_plate: x.trailer_plate,
            subby_id: x.subby,
            subby_name: subbyList?.find((current) => current.value === x.subby)?.label,
          };
        });

        const requestObject: AssignTruckRequest = {
          truck_list: trucksList,
          remark: value.remark_to_customer,
        };

        setAssignTruckRequest(requestObject);

        if (requestObject) {
          setIsModalConfirmOpen(true);
        }
      })
      .catch(() => {});
  };

  const onClickConfirm = () => {
    if (assignTruckRequest) {
      if (bookingInfo?.status === "confirmed") {
        BookingRequestService.requestUpdateTruck(bookingId, assignTruckRequest)
          .then((res) => {
            if (res) {
              BookingRequestService.truckUpdated.next(bookingId);
              setIsModalConfirmOpen(false);
              navigate("/booking-request");
            }
          })
          .catch(() => {
            setIsError(true);
          });
      } else {
        BookingRequestService.requestAssignTruck(bookingId, assignTruckRequest)
          .then((res) => {
            if (res) {
              BookingRequestService.truckAssigned.next(bookingId);
              setIsModalConfirmOpen(false);
              navigate("/booking-request");
            }
          })
          .catch(() => {
            setIsError(true);
          });
      }
    }
  };

  const onClickBack = () => {
    setIsModalConfirmOpen(false);
  };
  const updatePhoneNumberField = (index: number, rawValue: string) => {
    const cleanedValue = formatPhoneNumber(rawValue);
    form.setFields([
      {
        name: ["trucks", index, "driver_mobile"],
        value: cleanedValue,
      },
    ]);
  };

  return (
    <>
      <Modal
        centered
        open={open}
        onCancel={onClickCancle}
        title={<h3 style={{ fontWeight: "bold" }}>การมอบหมายงาน</h3>}
        closeIcon
        style={{
          margin: "24px",
        }}
        styles={{
          footer: {
            marginTop: "16",
          },
        }}
        footer={
          <>
            <ButtonDs type="default" onClick={onClickCancle}>
              ยกเลิก
            </ButtonDs>
            <ButtonDs htmlType="submit" type="primary" onClick={form.submit}>
              ถัดไป
            </ButtonDs>
          </>
        }
      >
        {
          <FormDs form={form} onFinish={onClickSubmit}>
            <Row gutter={[16, 16]}>
              <Form.List name="trucks" initialValue={[{ truck_type: bookingInfo?.truck_type }]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Col span={24} key={key}>
                        <CardBox
                          type="secondary"
                          title={
                            fields.length > 1 ? (
                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>รถขนส่ง {fields.length > 1 ? `(คันที่ ${index + 1})` : ""}</div>
                                {fields.length > 1 ? (
                                  <CloseOutlined
                                    className="dynamic-delete-button"
                                    onClick={() => remove(name)}
                                  />
                                ) : null}
                              </div>
                            ) : null
                          }
                        >
                          <Col span={24}>
                            <Row>
                              <Form.Item
                                label="ประเภทขนส่ง"
                                name={[name, "truck_owner"]}
                                initialValue="own_fleet"
                                rules={[{ required: true, message: "กรุณาเลือกประเภทขนส่ง" }]}
                                {...restField}
                              >
                                <Radio.Group
                                  value="own_fleet"
                                  onChange={() => {
                                    setFormUpdated(!formUpdated);
                                  }}
                                  options={[
                                    {
                                      value: "own_fleet",
                                      label: "Own fleet",
                                    },
                                    {
                                      value: "subby",
                                      label: "Subby",
                                    },
                                  ]}
                                />
                              </Form.Item>
                              {form.getFieldValue("trucks")[index] &&
                                form.getFieldValue("trucks")[index].truck_owner === "subby" && (
                                  <Form.Item
                                    label={
                                      <div
                                        style={{
                                          position: "absolute",
                                          left: "-5px",
                                          zIndex: 100,
                                          background: "#FAFAFA",
                                        }}
                                      >
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                      </div>
                                    }
                                    name={[name, "subby"]}
                                    {...restField}
                                    rules={[{ required: true, message: "กรุณาเลือกประเภทขนส่ง" }]}
                                  >
                                    <SelectDs
                                      showSearch={true}
                                      options={subbyList}
                                      style={{ width: "200px" }}
                                    />
                                  </Form.Item>
                                )}
                            </Row>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              label="ประเภทรถ"
                              name={[name, "truck_type"]}
                              rules={[{ required: true, message: "กรุณาเลือกประเภทรถ" }]}
                              {...restField}
                            >
                              <SelectDs options={truckTypes.map((x) => ({ value: x, label: x }))} />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item
                                  label="ป้ายทะเบียนหัว"
                                  name={[name, "truck_plate"]}
                                  rules={[{ required: true, message: "กรุณากรอกป้ายทะเบียนหัว" }]}
                                  {...restField}
                                >
                                  <InputDs
                                    style={{ width: "auto" }}
                                    onBlur={(v) => {
                                      const trimValue: string = v.target.value.trim();
                                      form.setFields([
                                        {
                                          name: ["trucks", index, "truck_plate"],
                                          value: trimValue,
                                        },
                                      ]);
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  label="ป้ายทะเบียนหาง"
                                  name={[name, "trailer_plate"]}
                                  {...restField}
                                >
                                  <InputDs
                                    style={{ width: "auto" }}
                                    onBlur={(v) => {
                                      const trimValue: string = v.target.value.trim();
                                      form.setFields([
                                        {
                                          name: ["trucks", index, "trailer_plate"],
                                          value: trimValue,
                                        },
                                      ]);
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              label="พนักงานขับรถ"
                              name={[name, "driver_name"]}
                              rules={[{ required: true, message: "กรุณากรอกชื่อพนักงานขับรถ" }]}
                              {...restField}
                            >
                              <InputDs
                                width="100%"
                                onBlur={(v) => {
                                  const value = v.target.value;
                                  const rawNameValue = value.trim();
                                  const trimNameValue = rawNameValue.replace(/\s+/g, " ");
                                  form.setFields([
                                    {
                                      name: ["trucks", index, "driver_name"],
                                      value: trimNameValue,
                                    },
                                  ]);
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              label="เบอร์โทรศัพท์"
                              name={[name, "driver_mobile"]}
                              rules={[{ validator: phoneNumberValidator }]}
                              normalize={(value) => formatPhoneNumber(value)}
                              {...restField}
                            >
                              <InputDs
                                type="phone"
                                width="100%"
                                onChange={(e) => {
                                  const { value } = e.target;
                                  updatePhoneNumberField(index, value);
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </CardBox>
                      </Col>
                    ))}
                    {bookingInfo && bookingInfo?.dropoff_list.length > 1 ? null : (
                      <Col span={24}>
                        <ButtonDs
                          type="text"
                          onClick={() => {
                            console.log(form.getFieldValue("trucks")[0]);
                            if (fields.length < 4) {
                              add();
                            }
                          }}
                          icon={<PlusOutlined />}
                        >
                          เพิ่มรถขนส่ง
                        </ButtonDs>
                      </Col>
                    )}
                  </>
                )}
              </Form.List>

              <Col span={24}>
                <CardBox type="secondary">
                  <Col span={24}>
                    <Form.Item label="หมายเหตุถึงลูกค้า" name="remark_to_customer">
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>
                </CardBox>
              </Col>
            </Row>
          </FormDs>
        }
      </Modal>
      <ModalConfirmAssignTruck
        assignTruckRequest={assignTruckRequest}
        open={isModalConfirmOpen}
        onClickBack={onClickBack}
        onClickConfirm={onClickConfirm}
        isError={isError}
      />
    </>
  );
};

export default ModalAssignTruck;

const ModalConfirmAssignTruck = ({
  open,
  assignTruckRequest,
  onClickBack,
  onClickConfirm,
  isError,
}: {
  open: boolean;
  assignTruckRequest: AssignTruckRequest | undefined;
  onClickBack: () => void;
  onClickConfirm: () => void;
  isError: boolean;
}) => {
  const renderContentDetail = (title: string, content: string) => {
    return (
      <div style={{ lineHeight: 1.5 }}>
        <div style={{ fontSize: "12px", color: "#272A31" }}>{title}</div>
        <div style={{ fontSize: "16px" }}>{content}</div>
      </div>
    );
  };

  return (
    <>
      <Modal
        closeIcon
        onCancel={onClickBack}
        title="ยืนยันการระบุรถขนส่ง"
        open={open}
        centered
        footer={
          <>
            <ButtonDs type="default" onClick={onClickBack}>
              ย้อนกลับ
            </ButtonDs>
            <ButtonDs htmlType="submit" type="primary" onClick={onClickConfirm}>
              ยืนยัน
            </ButtonDs>
          </>
        }
      >
        <Row gutter={[16, 16]}>
          <Col span={24} style={{ display: isError ? "" : "none" }}>
            <AlertMessage
              type="error"
              message="เกิดข้อผิดพลาด"
              description="ไม่สามารถดำเนินการได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง"
            />
          </Col>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              {assignTruckRequest &&
                assignTruckRequest.truck_list?.map((truckInfo, index) => (
                  <React.Fragment key={index}>
                    <Col span={24}>
                      <CardBox
                        type="secondary"
                        title={
                          assignTruckRequest && assignTruckRequest.truck_list.length > 1 ? (
                            <div>
                              รถขนส่ง{" "}
                              {assignTruckRequest.truck_list.length > 1 ? `(คันที่ ${index + 1})` : ""}
                            </div>
                          ) : null
                        }
                      >
                        <Row gutter={[16, 16]}>
                          <Col span={24}>
                            {renderContentDetail(
                              "ประเภทขนส่ง",
                              truckInfo.truck_owner === "own_fleet"
                                ? "Own Fleet"
                                : truckInfo.subby_name || "-",
                            )}
                          </Col>
                          <Col span={12}>{renderContentDetail("ประเภทรถ", truckInfo.truck_type)}</Col>
                          <Col span={12}>
                            {renderContentDetail("ป้ายทะเบียนหัว", truckInfo.truck_plate || "-")}
                          </Col>
                          <Col span={24}>
                            {renderContentDetail("ป้ายทะเบียนหาง", truckInfo.trailer_plate || "-")}
                          </Col>
                          <Col span={24}>
                            {renderContentDetail("พนักงานขับรถ", truckInfo.driver_name || "-")}
                          </Col>
                          <Col span={24}>
                            {renderContentDetail("เบอร์โทรศัพท์", truckInfo.driver_mobile || "-")}
                          </Col>
                        </Row>
                      </CardBox>
                    </Col>
                  </React.Fragment>
                ))}
            </Row>
          </Col>
          <Col span={24}>
            <CardBox type="secondary">
              <Col span={24}>{renderContentDetail("หมายเหตุ", assignTruckRequest?.remark || "-")}</Col>
            </CardBox>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
