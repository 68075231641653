import ApiClient from "../../shared/api/ApiClient";
import {
  DOWNLOAD_RECEIVED_ORDER_REPORT,
  DOWNLOAD_SPEED_LIMIT_REPORT,
  DOWNLOAD_TASK_STATUS_REPORT,
} from "../../shared/api/ApiEndPoint";

export const downloadTaskStatusReport = (plannedArrivalDate: string): Promise<any> =>
  ApiClient.get(DOWNLOAD_TASK_STATUS_REPORT(plannedArrivalDate), {
    responseType: "blob",
    timeout: 120000,
  }).then((res) => res);

export const downloadSpeedLimitReport = (plannedArrivalDate: string): Promise<any> =>
  ApiClient.get(DOWNLOAD_SPEED_LIMIT_REPORT(plannedArrivalDate), {
    responseType: "blob",
  }).then((res) => res);

export const downloadRecievedOrderReport = (startDate: string, endDate: string): Promise<any> =>
  ApiClient.get(DOWNLOAD_RECEIVED_ORDER_REPORT, {
    params: { start_date: startDate, end_date: endDate },
    responseType: "blob",
  }).then((res) => res);
