import { Modal } from "antd";
import { ButtonDs } from "design-system";

interface Props {
  title: React.ReactNode;
  onClickConfirmCancel: () => void;
  onClickConfirmOK: () => void;
  open: boolean;
  loading?: boolean;
  children: React.ReactNode;
  getContainer?: boolean;
}
const ConfirmModal = ({ title, onClickConfirmCancel, onClickConfirmOK, open, children, loading }: Props) => {
  return (
    <Modal
      maskClosable={false}
      centered
      closeIcon={false}
      open={open}
      title={title}
      onCancel={onClickConfirmCancel}
      footer={
        <>
          <ButtonDs type="default" onClick={onClickConfirmCancel} disabled={loading}>
            ยกเลิก
          </ButtonDs>
          <ButtonDs htmlType="submit" type="primary" onClick={onClickConfirmOK} loading={loading}>
            ยืนยัน
          </ButtonDs>
        </>
      }
    >
      {children}
    </Modal>
  );
};

export default ConfirmModal;
